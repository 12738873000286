import React from 'react'

const Newsletter = ({ trans }) => {
  return (
    <div className="flex justify-center">
      <div className="newsletter-container">
        <div className="vinyl-background"></div>

        <div className="vinyl-text pt-10 pl-5">

          <h2 className="mr-5 text-6xl text-white tracking-wide flex justify-center mt-20 mb-8">{trans.newsletterTitle}</h2>
          <p className="mr-5 text-xl text-white tracking-wide flex justify-center uppercase mt-10">{trans.newsletterTextCTA}</p>
          <div className="mr-5 text-xl tracking-wide flex justify-center mt-8">
            <a className="uppercase bg-white pt-2 pb-2 pl-6 pr-6 rounded-md font-bold" href='https://mailchi.mp/4f5b67c44f1c/les-tireux-droches' target="blank">{trans.newsletterBtnCTA}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Newsletter
