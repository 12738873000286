import React, { useEffect } from 'react'

const Bandsintown = ({ trans, language }) => {

  useEffect(() => {
    document.getElementById('widgetFr').style.display = "block"
    document.getElementById('widgetEn').style.display = "block"
    setTimeout(HideWidget, 100);
    function HideWidget() {
      if (language === "fr") {
        document.getElementById('widgetFr').style.display = "block"
        document.getElementById('widgetEn').style.display = "none"

      }
      if (language === "en") {
        document.getElementById('widgetEn').style.display = "block"
        document.getElementById('widgetFr').style.display = "none"
      }
    }
  }, [language])

  return (
    <section id="shows">
      <h2 className="mr-5 text-6xl text-white tracking-wide flex justify-center mt-20 mb-20">{trans.shows}</h2>

      <div className="bit-widget-container" id='widgetEn'>
        <p className="bit-widget-initializer"

          data-artist-name="Les Tireux d'Roches"

          data-display-details="false"
          data-display-start-time="true"
          data-display-year="true"
          data-text-color="#ffffff"
          data-background-color="#83717100"
          data-separator-color="#DDDDDD00"
          data-popup-background-color="#ffffff"
          data-link-color="undefined"
          data-link-text-color="#ffffff"
          data-display-limit="10"
          data-language="en"

        ></p>
      </div>
      <div className="bit-widget-container" id='widgetFr'>
        <p className="bit-widget-initializer"

          data-artist-name="Les Tireux d'Roches"

          data-display-details="false"
          data-display-start-time="true"
          data-display-year="true"
          data-text-color="#ffffff"
          data-background-color="#83717100"
          data-separator-color="#DDDDDD00"
          data-popup-background-color="#ffffff"
          data-link-color="undefined"
          data-link-text-color="#ffffff"
          data-display-limit="10"
          data-language="fr"

        ></p>
      </div>

    </section>
  )
}

export default Bandsintown
