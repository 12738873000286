import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";

const About = ({ trans, language }) => {
  const [about, setAbout] = useState();

  useEffect(() => {
    async function callSanity() {
      await sanityClient
        .fetch(`*[_type == "about"][0] {
          "title": title.${language},
          "body": body.${language},
          "members": members.${language},
          aboutImage{
            asset->{
              _id,
              url
            },
            alt
          }          
        }`
        )
        .then((data) => setAbout([data]))
        .catch(console.error)
    }
    callSanity()
  }, [language])


  return (
    <section id="about">
      <h2 className="mr-5 text-6xl text-white tracking-wide flex justify-center mt-20 mb-20">{trans.about}</h2>
      <div className="flex justify-center flex-wrap m-5">

        {about && about.map((content) => (
          <div className="tracking-wide max-w-lg text-light-gray" key={Math.random()}>
            <h3 className="mr-5 text-4xl text-light-gray tracking-wide mb-3">{content.title}</h3>
            <div className="blockContent-container">
              <BlockContent
                blocks={content.body}
                projectId="88tzt1mz"
                dataset="production" />
            </div>
            <img className="about_img" src={content.aboutImage.asset.url} alt={content.aboutImage.alt} />
            <div className="text-sm text-center blockContent-container">
              <BlockContent
                blocks={content.members}
                projectId="88tzt1mz"
                dataset="production" />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default About
