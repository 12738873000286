import './App.css';
import NavBar from './components/NavBar';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import StickyFooter from './components/StickyFooter';
import { withTranslation } from 'react-i18next';
import LanguageContext, { languages } from './components/language-context';
import { useEffect, useState } from 'react';
import i18n from './i18n';



function App({ t }) {

  const [language, setLanguage] = useState(languages.french);
  const toggleLanguage = () => {
    language === languages.french
      ? setLanguage(languages.english)
      : setLanguage(languages.french)
  }

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language])

  const trans = {
    "shows": t('shows'),
    "music": t('music'),
    "videos": t('videos'),
    "contacts": t('contacts'),
    "newsletter": t('newsletter'),
    "about": t('about'),
    "discography": t('discography'),
    "newsletterTitle": t('newsletterTitle'),
    "newsletterTextCTA": t('newsletterTextCTA'),
    "newsletterBtnCTA": t('newsletterBtnCTA'),
    "transBtn": t('transBtn')
  }

  return (
    <LanguageContext.Provider value={language}>
      <NavBar trans={trans} toggleTrans={toggleLanguage} language={language} />
      <HomePage trans={trans} language={language} />
      <StickyFooter />
      <Footer />
    </LanguageContext.Provider>
  );
}

export default withTranslation()(App);
