import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";

const Hero = ({ language }) => {
    const [hero, setHero] = useState();

    useEffect(() => {
        async function callSanity() {
            await sanityClient
                .fetch(`*[_type == "hero"][0] {
                "title": title.${language},
                "subtitle": subtitle.${language},
                heroImage{
                    asset->{
                      _id,
                      url
                    },
                    alt
                  }          
                }`
                )
                .then((data) => setHero([data]))
                .catch(console.error)
        }
        callSanity()
    }, [language])
    return (
        <section id="hero">
            {hero && hero.map((hero) => (

                <div className="relative text-center hero-container" key={hero.heroImage.asset._id}>
                    <img className="hero_img" src={hero.heroImage.asset.url} alt="Artwork" />
                    <h1 className="hero_img_txt">{hero.title}</h1>
                    <h2 className="hero_subtitle text-white">{hero.subtitle}</h2>
                </div>
            ))}

            <div></div>



        </section>
    )

}
export default Hero