import React from 'react';

const ChangeLanguage = ({ trans, toggleTrans }) => {

  return (
    <div className="lang">
      <span onClick={toggleTrans} className='hover:underline my-5 md:my-0 md:mr-5 text-6xl lg:text-xl text-white tracking-wide translateBtn'>{trans.transBtn}</span>
    </div>
  )
}

export default ChangeLanguage;