import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";


const Video = ({ trans }) => {

  const [ids, setIds] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "youtube"]|order(orderRank){
        url
      }`
      )
      .then((data) => {
        let videoIds = []
        for (let i = 0; i < data.length; i++) {
          let url = data[i].url;
          var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
          var match = url.match(regExp);
          videoIds.push({ videoId: (match && match[7].length === 11) ? match[7] : false })
        }
        setIds(videoIds)
      })
      .catch(console.error)
  }, [])

  return (
    <section id="videos" className="flex flex-col items-center">
      <h2 className="mr-5 text-6xl text-white tracking-wide flex justify-center mt-20 mb-20">{trans.videos}</h2>
      <div className="">
        {ids && ids.map((id) => (
          <div className="video-container block" key={id.videoId}>
            <iframe className="video" key={id.videoId} src={"https://www.youtube.com/embed/" + id.videoId + "?controls=0"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Video
