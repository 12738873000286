import React from "react";
import Tdr from "../assets/images/logo/TdR.svg"
import ChangeLanguage from "../ChangeLanguage";

const NavBar = ({ trans, toggleTrans, language }) => {


    function showNavBarMobile() {
        document.getElementById("nav-content").classList.toggle("hidden");
    }

    return (
        <nav className="flex items-center justify-between flex-wrap p-6 fixed w-full z-10 top-0 topNav">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <a className="text-white no-underline hover:text-white hover:no-underline" href="/">
                    <img src={Tdr} alt="Logo TdR" className="h-9 lg:h-12" />
                </a>
            </div>

            <div className="block lg:hidden">
                <button onClick={() => showNavBarMobile()} id="nav-toggle" className="flex items-center px-3 py-2  text-white hover:text-white">
                    <svg className="fill-current h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                </button>
            </div>

            <div className="flex flex-col w-full h-screen lg:h-auto flex-grow lg:flex-row items-center lg:w-auto hidden lg:block pt-6 lg:pt-0 text-center" id="nav-content">
                <ul className="list-reset lg:h-auto lg:flex justify-end flex-1 items-center">
                    <li className="mr-3 mt-4 lg:mt-0">
                        <a onClick={() => showNavBarMobile()} className="hover:underline my-5 md:my-0 md:mr-5 text-6xl lg:text-xl text-white tracking-wide" href="#shows">{trans.shows}</a>
                    </li>
                    <li className="mr-3 mt-8 lg:mt-0">
                        <a onClick={() => showNavBarMobile()} className="hover:underline my-5 md:my-0 md:mr-5 text-6xl lg:text-xl text-white tracking-wide" href="#music">{trans.music}</a>
                    </li>
                    <li className="mr-3 mt-8 lg:mt-0">
                        <a onClick={() => showNavBarMobile()} className="hover:underline my-5 md:my-0 md:mr-5 text-6xl lg:text-xl text-white tracking-wide" href="#videos">{trans.videos}</a>
                    </li>
                    <li className="mr-3 mt-8 lg:mt-0">
                        <a onClick={() => showNavBarMobile()} className="hover:underline my-5 md:my-0 md:mr-5 text-6xl lg:text-xl text-white tracking-wide" href="#contact">{trans.contacts}</a>
                    </li>
                    <li className="mr-3 mt-8 lg:mt-0">
                        <a rel="noopener noreferrer" target="_blank" href="https://mailchi.mp/4f5b67c44f1c/les-tireux-droches" className="my-5 md:my-0 md:mr-5 text-md px-6 py-3 text-white tracking-wide button hover:">{trans.newsletter}</a>
                    </li>
                    <li className="mr-3 mt-8 lg:mt-0">
                        <ChangeLanguage trans={trans} toggleTrans={toggleTrans} language={language} />
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default (NavBar);