import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";


const Contacts = ({ trans, language }) => {

  const [contacts, setContacts] = useState();

  useEffect(() => {
    async function callSanity() {
      await sanityClient
        .fetch(`*[_type == "contacts"] {
        "role": role.${language},
        agency,
        phone,
        name,
        email
      }`
        )
        .then((data) => setContacts(data))
        .catch(console.error)
    }
    callSanity()
  }, [language])

  return (
    <section id="contact">
      <h2 className="mr-5 text-6xl text-white tracking-wide flex justify-center mt-20 mb-20">{trans.contacts}</h2>

      <div className="flex flex-wrap justify-center">
        {contacts && contacts.map((contact) => (
          <div key={Math.random()} className="flex flex-col p-4 card space-between">
            <div className="flex-grow">
              <p className="card-role">{contact.role}</p>
              <p className="card-agency">{contact.agency}</p>
            </div>
            <div >
              <p className="card-phone">{contact.phone}</p>
              <p className="card-name">{contact.name}</p>
              <a href={"mailto:" + contact.email} className="card-email">{contact.email}</a>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Contacts
